.task-group {
  display: flex;
  margin: 15px 0;
  position: relative;
}

.task {
  padding: 5px 10px;
  font-size: medium;
  background-color: white;
  resize: none;
  width: 100%;
  text-align: center;
  border-radius: var(--border-radius);
  outline: 0;
  border: 1px solid rgba(0, 0, 128, 0.5);
}


.task__buttons-container-left {
  padding: 2px 10px;
  position: absolute;
  top: 5px;
  left: 0px;
  width: 25px;
  display: flex;
}

.task__buttons-container-right {
  padding: 2px 10px;
  position: absolute;
  top: 5px;
  right: 33px;
  width: 25px;
  display: flex;
}

.task__btn {
  height: 35px;
  width: 42px;
  align-self: center;
  margin-right: 5px;
  border-radius: var(--border-radius);
  font-weight: 900;
  user-select: none;
  text-transform: uppercase;
  background-color: transparent;
}

.btn_reorder {

}

.btn__complete {
  border: 2px solid navy;
  color: navy;
}

.btn__complete:hover {
  border-color: green;
  color: green;
}