@import "./css/_variables.css";
@import "./css/Task.css";
@import "./css/Navbar.css";
@import "./css/Header.css";

body {
  display: flex;
  justify-content: center;
  position: relative;
  color: navy;
  min-height: 98vh;
}

#react-app {
  width: 100%;
}

.gear-icon {
  width: 26px;
  max-width: unset;
}

#side-panel {
  position: fixed;
  right: 10px;
  top: 10px;
  height: 90vh;
  max-height: 600px;
  background-color: white;
  width: 60%;
  min-width: 260px;
  max-width: 400px;
  z-index: 999;
  color: navy;
  box-shadow: 0 0 5px grey;
  padding: 0px 10px;
}

#side-panel > header {
  display: flex;
}

#side-panel > header > .side-panel-header {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

#side-panel .gear-icon-wrapper {
  margin: 0 10px;
}

#side-panel hr {
  margin: 1rem 0;
}

#side-panel .close-icon {
  font-size: 36px;
  color: navy;
  cursor: pointer;
}

#side-panel .version {
  position: absolute;
  bottom: 10px;
  left: 50%;
  font-size: smaller;
}

#side-panel .misc {
  position: absolute;
  bottom: 40px;
  padding: 20px;
}

.title {
  color: navy !important;
}

.title.is-2 {
  font-size: 1.5rem;
}

main#main-wrapper {
  margin: 0 auto;
  max-width: 600px;
  padding: 10px;
}

@media only screen and (max-width: 653px) {
  #tasks-wrapper {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 653px) {
  main#main-wrapper {
    margin-top: 30px;
  }
}

.by-nazreen {
  display: block;
  margin-top: -20px;
}

section {
  display: flex;
  flex-direction: column;
}

section#tabs {
  flex-direction: row;
  justify-content: space-evenly;
  padding: 15px 0;
}

input.tab-title {
  text-align: center;
}

.tab-title:hover {
  cursor: pointer;
}

.btn {
  padding: 2px 10px;
  user-select: none;
  background-color: transparent;
  border: 2px solid navy;
  color: navy;
  align-self: center;
  border-radius: 2px;
  cursor: pointer;
}


.strikethrough {
  text-decoration: line-through;
}

.grey-bg {
  background-color: lightgrey;
}

#completed-list {
  width: 80%;
  margin: 0 auto;
}

.completed-list_description {
  text-align: center
}

#completed-list_contents {
  padding: 10px
}

.completed-item {
  height: 5px;
  width: 5px;
  background-color: green;
  font-size: 0;
  display: inline-block;
  margin: 1px
}

#completed-list > .card {
  padding: 10px 20px;
  margin: 20px 0;
}

.clear-button {
  display: block;
  margin: 0 auto
}

#reminder {
  text-align: center;
}

#meta {
  text-align: center;
  color: grey;
}

#meta > header > p {
  margin-top: 50px;
  margin-bottom: 2px;
}

#meta ul {
  list-style: none;
}

.buy-me-coffee {
  padding: 10px 0;
}

.app-error {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.focused-view {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
}

.description {
  text-align: center;
  display: block;
}

#app-title {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.setting-item {
  display: flex;
  justify-content: space-evenly;
}

.setting-item .option {
  cursor: pointer;
  font-size: smaller;
}

#side-panel main {
  margin-top: 20px;
}

.app-footer {
  position: absolute;
  bottom: 10px;
  width: 90vw;
  text-align: center;
  font-size: smaller;
}

.empty-completed > img {
  width: 60px;
  display: block;
  margin: 0 auto;
}

.auth-form form {
  padding: 5px;
}

.auth-form form > input {
  display: block;
  margin: 5px;
}

.auth-form form > .btn {
  display: inline-block;
  margin: unset;
  margin-left: 5px;
  text-align: center;
  width: 42.5%;
}

.auth-form form > .btn.register {
  border: unset;
}

.form-input {
  width: 80%;
  min-width: 200px;
  border-color: 'navy';
  padding: 2px;
  border-radius: 2px;
}
