#container {
  height: 100%;
  width: 100%;
  display: flex;
}
#outer {
  margin: auto;
  /* margin-top: 15%; */
  background: #44b9fc;
  height: 50px;
  width: 50px;
  border-radius: 15px;
}
#middle {
  height: 30px;
  width: 30px;
  margin: auto;
  position: relative;
  top: 10px;
  border-radius: 50%;
  background-image: linear-gradient(150deg, transparent 50%, #154e7d 50%),
    linear-gradient(90deg, #154e7d 50%, white 50%);
  animation: rotation 1200ms infinite linear;
  -webkit-animation: rotation 1200ms infinite linear;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}
#inner {
  background: #44b9fc;
  height: 24px;
  width: 24px;
  margin: auto;
  position: relative;
  top: 3px;
  border-radius: 50%;
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }
  to {
    -webkit-transform: rotate(630deg);
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }
  to {
    -webkit-transform: rotate(630deg);
  }
}
