#hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#hero > * {
  margin: 0 10px
}

.logo {
  height: 30px;
  border-radius: 10%;
}
