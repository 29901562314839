#navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  border-bottom: 1px solid rgba(0,0,128,0.1);
  padding: 10px;
  background-color: rgba(248,248,248,0.75);
}